<template>
  <div>
    <van-tabs v-model="active" background="#f8f8f8">
      <van-tab title="已使用"></van-tab>
      <van-tab title="已过期"></van-tab>
    </van-tabs>
    <oList class="ul_li"></oList>
  </div>
</template>

<script>
import oList from "./components/list.vue";
import { Tab, Tabs } from "vant";
export default {
  components: {
    vanTab: Tab,
    vanTabs: Tabs,
    oList,
  },
  data() {
    return {
      active: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

::v-deep {
  .van-tabs__wrap {
    display: block;
  }
  .van-tabs__line {
    width: rem(36);
    bottom: 18px;
  }
}
.ul_li {
  margin-top: rem(40);
}
</style>
